import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import React, {
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdown from "../../../../RAFComponents/Inputs/RAFDropdown";
import RAFForm, {
  Condition,
  ConditionNot,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFMultiSelectDropdownMUI from "../../../../RAFComponents/Inputs/RAFMultiSelectDropdownMUI";
import {
  EvaluateRAFFormRules,
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import { getCreateComponent } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  FormLibraryRow,
  RAFRuleAction,
} from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { LookUpGroupRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import { RelatedTo } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  createActivityTable,
  deleteActivityDataByUID,
  retrieveActivityObjectByUIDAndType,
  saveActivityData,
} from "../../../../WorkesioRepository/Activity/ActivityRepository";
import * as RAFDatabase from "../../../../WorkesioRepository/index";
import classicFormImg from "../../../../assets/CareESIO/ClassicForm.png";
import cardFormImg from "../../../../assets/CareESIO/cardForm.svg";
import ACAutoCompleteMUI from "../../../../components/shared/ACFormFields/ACAutoCompleteMUI";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  CareRecipientPermissionConstants
} from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { createUpdateActivityRow } from "../../../ActiveContacts/Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
  RAFActivityVisibility,
} from "../../../ActiveContacts/Activity/ActivityRow";
import { getAllTeamMembersByEntityName } from "../../../ActiveContacts/UserGroup/UserGroupServiceHelper";
import { getRelatedCareTeamByRecipientID } from "../../CareTeam/CareTeamHelper";

interface IProps {
  initialValues?: ActivityRow;
  objectUID?: string;
  careRecipientUID: string;
  activityCommentType?: string;

  onClose: () => void;
  onSave: (isPinnedItem: boolean) => void;
  onDelete?: (isPinnedItem: boolean) => void;

  isActive: boolean;
  showPreviousBtn?: boolean;
  onPreviousBtnClick?: () => void;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  activityRow: ActivityRow;
  careTeamList: LookUpGroupRow[];
  showBackBtn: boolean;
  isPostFeed: boolean;
  selectedItemIndex: number;
}

function ManageClientNote({ ...props }: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const moduleName = RAFEntityName.Activity;

  const outerDivId = `manageupdate_clientnote_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      activityRow: null,
      careTeamList: null,
      isPostFeed: false,
      showBackBtn: props.showPreviousBtn
        ? true
        : isNotNullAndUndefined(props.objectUID) ||
          isNotNullAndUndefined(props.activityCommentType)
          ? false
          : true,
      selectedItemIndex: -1,
    }
  );

  useEffect(() => {
    refresh(props.activityCommentType);
  }, []);

  const refresh = async (activityCommentType: string) => {
    if (props.isActive) {
      setState({ isLoading: true });
      let [activityRow, allCareTeamList] = await Promise.all([
        getIntitailActivityRow(
          props.objectUID,
          props.initialValues,
          activityCommentType
        ),
        getRelatedCareTeamByRecipientID(props.careRecipientUID),
      ]);

      if (isNotNullAndUndefined(activityRow)) {
        const careTeamList: LookUpGroupRow[] = isNotEmptyArray(allCareTeamList)
          ? allCareTeamList.map((item) => ({
            UID: item.RelatedToUID,
            Value: item.RelatedTo,
            Catagory: item.RelatedToType,
          }))
          : [];

        if (isNotNullAndUndefined(activityRow)) {
          if (
            isNotNullAndUndefined(props.objectUID) &&
            activityRow.Visibility === RAFActivityVisibility.Private
          ) {
            const activityTeamMembers: RelatedTo[] =
              await getAllTeamMembersByEntityName(moduleName, props.objectUID);

            if (isNotEmptyArray(activityTeamMembers)) {
              activityRow.ActivityTeamMembers = activityTeamMembers.map(
                (item) => isNotNullAndUndefined(item.UID) && item.UID
              );
            } else {
              activityRow.ActivityTeamMembers = null;
            }
          } else if (activityRow.Visibility === RAFActivityVisibility.Public) {
            const careTeamListItemUIDs = isNotEmptyArray(careTeamList) ? careTeamList.map((item) => item.UID) : [];
            activityRow.ActivityTeamMembers = careTeamListItemUIDs;
          }
        }

        //adding in local db to prevent losing data
        let db = await RAFDatabase.get();
        db = createActivityTable(db);
        //if (IsNullOrWhiteSpace(props.objectUID)) {
        if (
          IsNullOrWhiteSpace(props.objectUID) &&
          (isNullOrUndefined(activityRow) ||
            IsNullOrWhiteSpace(activityRow.UID))
        ) {
          activityRow.UID = `${Guid.emptyGuid()}${activityCommentType || ""}`;
        }
        const localSaveActivityRow = await saveActivityData(activityRow);
        if (isNotNullAndUndefined(localSaveActivityRow)) {
          activityRow = localSaveActivityRow;
        }
        //adding in local db to prevent losing data

        setState({
          isLoading: false,
          noContent: false,
          activityRow,
          careTeamList,
          selectedItemIndex:
            isNotNullAndUndefined(props.objectUID) ||
              isNotNullAndUndefined(activityRow.CommentType)
              ? 1
              : 0,
          isPostFeed:
            activityCommentType === RAFActivityCommentType.Post ? true : false,
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailActivityRow = (
    objectUID: string,
    initialValues: ActivityRow,
    activityCommentType: string
  ) => {
    return new Promise<ActivityRow>(async (resolve) => {
      if (isNotNullAndUndefined(objectUID)) {
        const responseActivityRow: ActivityRow = await RetrieveRecord(
          objectUID,
          moduleName
        );
        if (
          isNotNullAndUndefined(responseActivityRow) &&
          isNotNullAndUndefined(responseActivityRow.UID)
        ) {
          const activityRow = { ...responseActivityRow };
          if (isNotNullAndUndefined(activityRow) && isNotNullAndUndefined(activityRow.UID)) {
            if (isNullOrUndefined(activityRow.HasMentions)) {
              activityRow.HasMentions = false;
            }
            if (isNullOrUndefined(activityRow.DisplayOrder)) {
              activityRow.DisplayOrder = 99;
            }
            if (isNullOrUndefined(activityRow.ColourCode)) {
              activityRow.ColourCode = "#FFF9B1";
            }
          }
          resolve(activityRow as ActivityRow);
        } else {
          resolve(null);
        }
      } else {
        //getting from local db if already exists
        const initialObjectDB = await retrieveActivityObjectByUIDAndType(
          `${Guid.emptyGuid()}${activityCommentType || ""}`,
          activityCommentType
        );
        //getting from local db if already exists
        if (
          isNotNullAndUndefined(initialObjectDB)
          //&& isNotNullAndUndefined(activityCommentType)
        ) {
          const initialObject = { ...initialObjectDB };
          // //map only the keys that are present in the ActivityRow class and remove others from initialObject
          // const keys = Object.keys(initialObject);
          // for (let key of keys) {
          //   if (!Object.keys(new ActivityRow()).includes(key)) {
          //     delete initialObject[key];
          //   }
          // }

          initialObject.PostFeed =
            activityCommentType === RAFActivityCommentType.Post ? true : false;
          initialObject.UserName = msalInstance.currentUserName;
          initialObject.UserUID = msalInstance.currentUserId;
          if (isNotNullAndUndefined(activityCommentType)) {
            initialObject.CommentType = activityCommentType;
          }
          if (isNotNullAndUndefined(initialValues)) {
            initialObject.RelatedToUID = initialValues.RelatedToUID;
            initialObject.RelatedTo = initialValues.RelatedTo;
            initialObject.RelatedToType = initialValues.RelatedToType;
            initialObject.SecondaryRelatedTo = initialValues.SecondaryRelatedTo;
            initialObject.SecondaryRelatedToUID =
              initialValues.SecondaryRelatedToUID;
            initialObject.SecondaryRelatedToType =
              initialValues.SecondaryRelatedToType;
          }
          resolve(initialObject);
        } else {
          let initialObject = isNotNullAndUndefined(initialValues)
            ? initialValues
            : new ActivityRow();
          initialObject.DisplayOrder = 99;
          initialObject.Visibility = RAFActivityVisibility.Public;
          initialObject.HasMentions = true;
          initialObject.Pinned = false;
          initialObject.PostFeed =
            activityCommentType === RAFActivityCommentType.Post ? true : false;
          initialObject.ColourCode = "#FFF9B1";
          initialObject.UserName = msalInstance.currentUserName;
          initialObject.UserUID = msalInstance.currentUserId;
          if (isNotNullAndUndefined(activityCommentType)) {
            initialObject.CommentType = activityCommentType;
          }
          resolve(initialObject);
        }
      }
    });
  };

  const onSubmitActivity = (value: ActivityRow) => {
    const activityToCreate: ActivityRow = { ...value } as ActivityRow;
    if (
      IsNotNullOrWhiteSpace(activityToCreate.Message) ||
      IsNotNullOrWhiteSpace(activityToCreate.Subject)
    ) {
      const activityTeamMembers = activityToCreate.ActivityTeamMembers;
      delete activityToCreate.ActivityTeamMembers;

      if (activityToCreate.Visibility === RAFActivityVisibility.Private) {
        const teamMembers: RelatedTo[] = [];
        const { careTeamList } = state;
        //if share with is users, then push all users as team members
        isNotEmptyArray(activityTeamMembers) &&
          activityTeamMembers.forEach((item) => {
            const selectedUser: LookUpGroupRow = isNotEmptyArray(careTeamList)
              ? careTeamList.find((x) => x.UID === item)
              : null;
            if (isNotNullAndUndefined(selectedUser)) {
              const selectedUserUID = selectedUser.UID;
              teamMembers.push({
                UID: selectedUserUID.toString(),
                Value: selectedUser.Value,
                Type: selectedUser.Catagory,
              });
            }
          });
        if (
          teamMembers.some((x) => x.UID === msalInstance.currentUserId) ===
          false
        ) {
          teamMembers.push({
            UID: msalInstance.currentUserId,
            Value: msalInstance.currentUserName,
            Type: "User",
          });
        }
        activityToCreate.TeamMembers = teamMembers;
      } else {
        activityToCreate.TeamMembers = [];
      }

      let progressDiv = showProgress(`#${outerDivId}`);
      //if care shift log have form post selectedFormEntityname , if not post "care_shift_log"
      //deleting from local db on save
      deleteActivityDataByUID(activityToCreate.UID, true);
      delete activityToCreate["IsDeleted"];
      delete activityToCreate["IsOffline"];
      delete activityToCreate["UpdatedAt"];
      delete activityToCreate["Synced"];
      delete activityToCreate["undefined"];
      if (IsNullOrWhiteSpace(props.objectUID)) {
        delete activityToCreate.UID;
      }
      activityToCreate.LastActivityDate = new Date();
      //deleting from local db on save

      createUpdateActivityRow(activityToCreate)
        .then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.entityId)
          ) {
            hideProgress(progressDiv);
            if (isNotNullAndUndefined(props.onSave)) {
              const isPinnedItem =
                state.activityRow.Pinned === true
                  ? true
                  : activityToCreate.Pinned === true
                    ? true
                    : false;
              props.onSave(isPinnedItem);
            }
          } else {
            hideProgress(progressDiv);
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    } else {
      showWarningToast("Please enter note or subject");
    }
  };

  //delete item start
  const onClickDeleteClientNote = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete Note`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    const { activityRow } = state;
    const isPinnedItem = activityRow.Pinned === true ? true : false;

    const isDeleted = await DeleteRecord(props.objectUID, RAFEntityName.Activity);

    //deleting from local db on delete
    if (IsNotNullOrWhiteSpace(activityRow.UID)) {
      const isActivityDataDeleted = await deleteActivityDataByUID(activityRow.UID, true);
    }
    //deleting from local db on delete

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete(isPinnedItem);
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(isPinnedItem);
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const onRAFFormOnchange = useCallback(
    (values?: any, formLibrary?: FormLibraryRow) => {
      let ruleActions: RAFRuleAction[] = formLibrary && formLibrary.FormRules;
      //updating in local db to prevent losing data
      const activityToCreate: ActivityRow = { ...values } as ActivityRow;
      saveActivityData(activityToCreate);
      //updating in local db to prevent losing data
      if (isNotNullAndUndefined(ruleActions))
        EvaluateRAFFormRules(values, ruleActions);
      return;
    },
    []
  );

  const onChangeColorCode = (args) => {
    setFormValue(
      rafForm,
      propertyOf<ActivityRow>("ColourCode"),
      args.currentValue.hex
    );
  };

  const onChangeHasMentions = (value) => {
    if (value === true) {
      const message = getFormValue(rafForm, propertyOf<ActivityRow>("Message"));
      let selectedUserIdArray = getSelectedUserIdArray(message);
      setFormValue(
        rafForm,
        propertyOf<ActivityRow>("Mentions"),
        selectedUserIdArray ?? []
      );
    } else {
      setFormValue(rafForm, propertyOf<ActivityRow>("Mentions"), []);
    }
  };

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }

  const mentionRightContent = () => {
    return (
      <div className="d-flex align-items-center ms-auto">
        {/* {state.isPostFeed !== true && (
          <RAFCheckBox
            field={propertyOf<ActivityRow>("Pinned")}
            label="Pinned"
            showLabel={false}
            validate={false}
            labelPosition="right"
            inputFieldClassName="col-auto"
            labelClassName="col"
            formGroupClassName="mb-0"
            rowClassName="row g-2 align-items-center"
            uitype={"pinToggle"}
            icon="fas fa-thumbtack text_orange"
            hideRequiredMessage
          ></RAFCheckBox>
        )} */}
        <div className="ms-2 me-1">
          <label className={"form-label"}>Mentions</label>
        </div>
        <RAFCheckBox
          field={propertyOf<ActivityRow>("HasMentions")}
          label="Has Mentions"
          showLabel={false}
          validate={false}
          labelPosition="right"
          inputFieldClassName="col-auto"
          labelClassName="col"
          formGroupClassName="mb-0"
          rowClassName="row g-2 align-items-center"
          uitype={"switch"}
          hideRequiredMessage
          onChanged={onChangeHasMentions}
        ></RAFCheckBox>
      </div>
    );
  };

  const getMessageTextArea = (useMentions: boolean) => {
    return (
      // <RAFTextArea
      //   field={propertyOf<ActivityRow>("Message")}
      //   label="Description"
      //   showLabel={true}
      //   required
      //   placeholder="Share your thoughts... use @ to mention someone"
      //   rows={5}
      //   /*onFocus={e => this.onFocus(e)}*/
      //   // onInputs={(e) => this.onInputs(e)}
      //   // onChanged={(e) => this.onFocus()}
      //   useMentions={useMentions}
      //   mentionsField={propertyOf<ActivityRow>("Mentions")}
      //   rowClassName="row g-0 gy-2"
      //   hideRequiredMessage
      // ></RAFTextArea>
      <RAFHtmlEditor
        field={propertyOf<ActivityRow>("Message")}
        label="Description"
        showLabel={true}
        //required
        placeholder="Share your thoughts... use @ to mention someone"
        rows={5}
        htmlEditorHeight="100%"
        fullHeight={false}
        labelRightSection={mentionRightContent()}
        rowClassName="row g-0 gy-2"
        mentionsMode="user"
        useMentions={useMentions}
        mentionsField={propertyOf<ActivityRow>("Mentions")}
        formGroupClassName="mb-0"
      />
    );
  };

  const onChangeVisibilityCheckBox = (isChecked: boolean) => {
    const visibility = isChecked ? RAFActivityVisibility.Public : RAFActivityVisibility.Private;
    if (visibility === RAFActivityVisibility.Public) {
      const careTeamListItemUIDs = isNotEmptyArray(state.careTeamList) ? state.careTeamList.map((item) => item.UID) : [];
      setFormValue(rafForm, propertyOf<ActivityRow>("ActivityTeamMembers"), careTeamListItemUIDs);
    };
    setFormValue(rafForm, propertyOf<ActivityRow>("Visibility"), visibility);
  };

  const getVisibilityCheckBoxInput = (isChecked: boolean) => {
    return (
      <div className="ms-auto d-flex justify-content-end align-items-center">
        <CheckBoxComponent
          name={`${propertyOf<ActivityRow>("Visibility")}_Everyone`}
          value={'Visibility'}
          className="custom-control-input"
          change={(e) => onChangeVisibilityCheckBox(e.checked)}
          checked={isChecked}
          label={'Everyone'}
        />
      </div>
    );
  };

  const getVisibilityInput = () => {
    const { careTeamList } = state;
    const loggedinUserId = msalInstance.currentUserId;
    const disableItemsValue = [loggedinUserId];

    return (
      <>
        {isNotEmptyArray(careTeamList) && (
          <div>
            <Field
              name={propertyOf<ActivityRow>("Visibility")}>
              {({ input }) => {

                const inputValue = input.value;
                const isDisabled = inputValue === RAFActivityVisibility.Private ? false : true;

                return (
                  <RAFMultiSelectDropdownMUI
                    field={propertyOf<ActivityRow>("ActivityTeamMembers")}
                    label="Who can see this note?"
                    placeholder="Select User(s)"
                    disableItems={disableItemsValue}
                    showClearButton={false}
                    allowFiltering
                    // uitype="checkbox"
                    dropdownCheckboxDivClass="col-sm-6 col-md-4"
                    formGroupClassName="mb-0"
                    rowClassName="gy-2"
                    labelRightSection={getVisibilityCheckBoxInput(isDisabled)}
                    disabled={isDisabled}
                  >
                    {careTeamList.map((item) => {
                      return (
                        <RAFChoiceOption
                          key={item.UID}
                          value={item.UID}
                          label={item.Value}
                        />
                      );
                    })}
                  </RAFMultiSelectDropdownMUI>
                );
              }}
            </Field>
          </div>
        )}
      </>
    );
  };

  const getSubjectInput = () => {
    const createForm = getCreateComponent(RAFEntityName.StandardizedLibrary);

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Note");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    return (
      <ACAutoCompleteMUI
        field={propertyOf<ActivityRow>("Subject")}
        label="Subject"
        placeholder="Select Subject"
        showLabel={true}
        url={"StandardizedLibrary/Lookup"}
        createform={createForm}
        moduleName={RAFEntityName.StandardizedLibrary}
        SearchCreateOptionMode={"Footer"}
        customFilter={customFilter}
        formGroupClassName={"mb-0"}
      />
    );
  };

  const getDisplayOrderSubjectColorPicker = () => {
    const paletteColors = {
      custom: [
        // "#F5F6F8",
        "#FFFBC7",
        "#E6FDC9",
        "#D6F0EF",
        "#D7E8FC",
        "#FDDECD",
        "#DED7FC",
        "#FDF2CE",
        "#E0E3EB",
        "#F5F5F5",
        "#D9F2DE",
        "#FDE2BA",
        "#FEDBDB",
        "#C4E8FE",
        // "#F5D027",
        // "#FF9D4A",
        // "#D6F693",
        // "#C9DF56",
        // "#67C6C2",
        // "#FFCFE1",
        // // "#EB93BB",
        // "#C6A2D2",
        // // "#F0939D",
        // // "#A6CBF5",
        // "#6CD9FA",
        // // "#9FA9FF",
      ],
    };

    const displayOrder = [99, 1, 2, 3, 4, 5];

    return (
      <div className="row gx-2">
        {state.isPostFeed !== true && (
          <div className="col-2">
            <RAFDropdown
              field={propertyOf<ActivityRow>("DisplayOrder")}
              label="Order"
              formGroupClassName={"mb-0 raf-caret-hide"}
              showClearButton={false}
            >
              {displayOrder.map((item, index) => {
                return (
                  <RAFChoiceOption
                    key={`${propertyOf<ActivityRow>("DisplayOrder")}_${index}`}
                    label={item === 99 ? "Any" : item.toString()}
                    value={item}
                  ></RAFChoiceOption>
                );
              })}
            </RAFDropdown>
            {/* <RAFNumber
          field={propertyOf<ActivityRow>("DisplayOrder")}
          label="Order"
          formGroupClassName={"mb-0"}
          showSpinButton={false}
        /> */}
          </div>
        )}
        <div className="col">{getSubjectInput()}</div>
        {state.isPostFeed !== true && (
          <div className="col-auto">
            <div className="form-label-container form-label-container-height mt-1"></div>
            <Field name={propertyOf<ActivityRow>("ColourCode")}>
              {({ input }) => {
                return (
                  <ColorPickerComponent
                    mode="Palette"
                    value={input.value}
                    showButtons={false}
                    modeSwitcher={false}
                    //columns={5}
                    change={(e) => {
                      onChangeColorCode(e);
                    }}
                    presetColors={paletteColors}
                  />
                );
              }}
            </Field>
          </div>
        )}
      </div>
    );
  };

  const getCommentTypeContent = () => {
    const commentType: {
      type: RAFActivityCommentType;
      img: string;
      description: string;
    }[] = [
        {
          type: RAFActivityCommentType.Alert,
          img: classicFormImg,
          description:
            "For urgent information requiring immediate attention, such as safety concerns or sudden changes in participant conditions.",
        },
        {
          type: RAFActivityCommentType.Update,
          img: cardFormImg,
          description:
            "For new information about a participant's NDIS plan, care, or recent events. Ideal for sharing GP visit summaries, therapy updates, or general case notes.",
        },
        {
          type: RAFActivityCommentType.Announcement,
          img: cardFormImg,
          description:
            "For important news to share with the whole team, like birthdays, achievements, policy changes, or upcoming events.",
        },
        {
          type: RAFActivityCommentType.Handover,
          img: cardFormImg,
          description:
            "For documenting key information when transferring care to another team member. Ensures smooth transitions and keeps everyone informed.",
        },
      ];

    return (
      <div className="e-dlg-body-content">
        <div className="row gy-3 justify-content-center">
          <div className="col-12">
            <div className="subtitle_1 semi_bold">
              Please specify the type of feed you'd like to add?
            </div>
          </div>
          {commentType.map((item) => {
            return (
              <div className="col-12" key={item.type}>
                <CustomCardWidget
                  onClick={() => onSelectCommentType(item.type)}
                  cardClickable
                >
                  {/* <div className="raf-avatar raf-avatar-xl circle mb-2">
                    <img src={item.img}></img>
                  </div> */}
                  <div className="subtitle_2 semi_bold">{item.type}</div>
                  <div className="body_2_light mt-2">
                    {item.description}
                  </div>
                </CustomCardWidget>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onSelectCommentType = async (commentType: string) => {
    if (state.activityRow.CommentType === commentType) {
      setState({ selectedItemIndex: 1 });
    } else {
      refresh(commentType);
    }
  };

  const onClickBackBtn = () => {
    if (props.onPreviousBtnClick) {
      props.onPreviousBtnClick();
    } else {
      setState({ selectedItemIndex: 0, isPostFeed: false });
    }
  };

  const getFormContent = () => {
    return (
      <div className="e-dlg-body-content">
        <div className="row gx-2 gy-4">
          <div className="col-md-12">{getDisplayOrderSubjectColorPicker()}</div>
          <div className="col-md-12">
            {/* <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <label className={"form-label"}>Note</label>

          </div>

        </div> */}
            <Condition when={"HasMentions"} is={true}>
              {getMessageTextArea(true)}
            </Condition>
            <Condition when={"HasMentions"} is={false}>
              {getMessageTextArea(false)}
            </Condition>
          </div>
          {/* <div className="col-md-12">
        <RAFNumber
          field={propertyOf<ActivityRow>("DisplayOrder")}
          label="Display Order"
          formGroupClassName={"mb-0"}
        />
      </div> */}
          {/* <div className="col-md-12">
        <div className="col-auto colorPicker">
          <Field name={propertyOf<ActivityRow>("ColourCode")}>
            {({ input }) => {
              return (
                <ColorPickerComponent
                  mode="Palette"
                  value={input.value}
                  showButtons={false}
                  modeSwitcher={false}
                  //columns={5}
                  change={(e) => {
                    onChangeColorCode(e);
                  }}
                  presetColors={paletteColors}
                />
              );
            }}
          </Field>
        </div>
      </div> */}
          {getVisibilityInput()}
          <div className="col-md-12">
            <div className="custom__card__separator custom__card__separator__light"></div>
          </div>
          {state.isPostFeed !== true && (
            <div className="col-md-12 mt-3">
              <RAFCheckBox
                field={propertyOf<ActivityRow>("Pinned")}
                label="Pin Note"
                validate={false}
                //labelPosition="right"
                formGroupClassName="mb-0"
                //uitype={"switch"}
                hideRequiredMessage
                inputFieldClassName="col-auto"
                labelClassName="col"
                rowClassName="row gx-2 align-items-center"
                uitype="switch"
              ></RAFCheckBox>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <div className="custom__card__separator custom__card__separator__light"></div>
          </div>
          {state.isPostFeed !== true && (
            <ConditionNot
              when={propertyOf<ActivityRow>("CommentType")}
              isNot={RAFActivityCommentType.Post}
            >
              <div className="col-md-12 mt-3">
                <RAFCheckBox
                  field={propertyOf<ActivityRow>("PostFeed")}
                  label="Publish the note to the feed"
                  validate={false}
                  //labelPosition="right"
                  formGroupClassName="mb-0"
                  //uitype={"switch"}
                  hideRequiredMessage
                  inputFieldClassName="col-auto"
                  labelClassName="col"
                  rowClassName="row gx-2 align-items-center"
                  uitype="switch"
                ></RAFCheckBox>
              </div>
            </ConditionNot>
          )}
        </div>
      </div>
    );
  };

  const getFooterContent = () => {
    const { activityRow } = state;
    const hasPermissionToDoAction
      = isNotNullAndUndefined(activityRow) && isNotNullAndUndefined(props.objectUID) ?
        ((activityRow.CreatedByUID === msalInstance.currentUserId && hasPermission(permissionValue, CareRecipientPermissionConstants.CareRecipientManageMyNotes)) ||
          (activityRow.CreatedByUID !== msalInstance.currentUserId && hasPermission(permissionValue, CareRecipientPermissionConstants.CareRecipientManageAllNotes))) :
        hasPermission(permissionValue, CareRecipientPermissionConstants.CareRecipientManageMyNotes);
    return (
      <div className="e-dlg-footerContent ">
        <div className="w-100">
          <div className="row gx-2">
            {state.showBackBtn && (
              <div className="col-auto">
                <div className="col-auto ms-auto">
                  <RAFButtonComponent
                    type="button"
                    action={RAFButtonConstant.Previous}
                    onClick={() => onClickBackBtn()}
                    idString="next_Content"
                    className="e-outline"
                  />
                </div>
              </div>
            )}
            {isNotNullAndUndefined(props.objectUID) && hasPermissionToDoAction && (
              <div className="col-auto">
                <RAFButtonComponent
                  action={RAFButtonConstant.Delete}
                  onClick={() => onClickDeleteClientNote()}
                  idString="DeleteContent"
                  className="e-danger e-outline"
                />
              </div>
            )}
            <div className="col-auto ms-auto">
              <RAFButtonComponent
                type="button"
                action={RAFButtonConstant.Cancel}
                onClick={props.onClose}
                idString="CreateContent"
              />
            </div>
            <div className="col-auto">
              {hasPermissionToDoAction && (
                <RAFButtonComponent
                  type="button"
                  isPrimary
                  action={RAFButtonConstant.Save}
                  onClick={() => rafForm && rafForm.form.submit()}
                  idString="CreateContent"
                  disabled={rafForm && rafForm.submitting}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <div className="h-100" id={outerDivId}>
            <RAFForm
              type={ActivityRow}
              initialValues={state.activityRow}
              formRef={(g) => (rafForm = g)}
              layout={RAFLayout.TwoColumnLayout}
              onSubmit={onSubmitActivity}
              className="h-100"
              onChange={(values) => onRAFFormOnchange(values)}
            >
              <div className="e-dlg-content-outer">
                <div className="e-dlg-headerContent justify-content-between">
                  <div className="e-dlg-header" id="_title">
                    <Field name={propertyOf<ActivityRow>("CommentType")}>
                      {({ input }) => {
                        return (
                          <>
                            {isNotNullAndUndefined(props.objectUID)
                              ? `Update ${input.value}`
                              : `Add ${input.value}`}
                          </>
                        );
                      }}
                    </Field>
                  </div>
                  <RAFButtonComponent
                    displayMode="DialogCloseBtn"
                    onClick={props.onClose}
                  ></RAFButtonComponent>
                  {/* <ButtonComponent
                    type="button"
                    cssClass="primary-custom-button"
                    iconCss="fas fa-xmark"
                    onClick={props.onClose}
                  ></ButtonComponent> */}
                </div>
                {state.selectedItemIndex === 0 && getCommentTypeContent()}
                {state.selectedItemIndex === 1 && (
                  <>
                    {getFormContent()}
                    {getFooterContent()}
                  </>
                )}
              </div>
            </RAFForm>
          </div>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <ButtonComponent
              type="button"
              cssClass="primary-custom-button"
              iconCss="fas fa-xmark"
              onClick={props.onClose}
            />
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageClientNote);
