import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";

export class InvoiceTransactionRow {
    UID?: string;
    Title?: string;
    StartDate?: Date;
    EndDate?: Date;
    ActualStartDate?: Date;
    ActualEndDate?: Date;
    Duration?: number;
    ActualDuration?: number;
    Type?: string;
    Status?: string;
    BillingStatus?: string;
    AssigneeUID?: string;
    Assignee?: string;
    AssigneeType?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    AssignTeam?: string;
    AssignTeamUID?: string;
    Priority?: string;
    SortOrder?: number;
    Description?: string;
    CommentCount?: number;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedToType?: string;
    SecondaryRelatedTo?: string;
    Shifts?:LookUpRow[];
    ShiftUID?: string;
    Shift?: string;
    ShiftType?:string;
    ServiceTransactionUID?: string;
    ServiceTransaction?: string;
    InvoiceTransactionIdNumber?: number;
    InvoiceTransactionId?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    RateType?: string;
    Rate?: string;
    LineItemNameUID?: string;
    LineItemName?: string;
    PayLoadingRate?: number;
    PayLoadingUID?: string;
    PayLoading?: string;
    BasicPay?: number;
    InvoiceAmount?: number;
    PayrollAmount?: number;
    InvoiceUID?: string;
    Invoice?: string;
    PayrollUID?: string;
    Payroll?: string;
    Frequency?: string;
    CronExpression?: string;
    RRuleExpression?: string;
    TagsListJson?: string[];
    Portal?: string;
    PortalUID?: string;
    Total?: number;
    Completed?: number;
    IndependenceLevel?: string;
    IndependenceLevelScore?: number;
    ServiceContractItem?: string;
    ServiceContractItemUID?: string;
    ServiceName?: string;
    ServiceCode?: string;
    Latitude?: string;
    Longitude?: string;
    State?: string;
    GroupUID?: string;
    TravelDistance?: number;
    EnableTravel?: boolean;

    Variant?: string;
    VariantUID?: string;

    // TravelVariant?: string;
    // TravelVariantUID?: string;

    IsBillable?: boolean;
    IsAutoInvoice?: boolean;

    ServiceType?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}
