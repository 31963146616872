import * as R from "ramda";
import React, { PropsWithChildren, useEffect } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFDurationPicker from "../../../../../RAFComponents/Inputs/RAFDurationPicker";
import RAFForm, {
  Condition,
  ConditionIncludes,
  ConditionNotIncludes,
  ConditionNotNullOrWhiteSpace,
} from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFNumber from "../../../../../RAFComponents/Inputs/RAFNumber";
import RAFTextArea from "../../../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../../../RAFComponents/Inputs/RAFTextBox";
import {
  getFormValue,
  setFormValue,
} from "../../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  convertToMinutes,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { EvaluateScore } from "../../../../../RAFMaster/helpers/RMutils";
import { InvoiceTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../../../constants/Common/RMConstants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import { ServiceTransactionRow } from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../../Common/Providers/RAFObjectContextProvider";
import { getUserTenantSettings } from "../../../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../../../Common/TenantSettings/TenantSettingsRow";
import {
  getServiceContractItemVariant,
  getTravelItemById,
} from "../../../CareServiceContractItem/CareServiceContractItemHelper";
import { ServiceContractItemRow } from "../../../CareServiceContractItem/ServiceContractItem";
import { InvoiceTransactionRow } from "./InvoiceTransactionRow";

interface IProps {
  serviceTransaction?: ServiceTransactionRow;
  serviceTransactions?: ServiceTransactionRow[];
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  shiftIds?: LookUpRow[];
  startDate?: Date;
  endDate?: Date;
}

interface IState {
  initialValues?: InvoiceTransactionRow;
  variantServiceContractItem?: ServiceContractItemRow;
  tenantSettings?: TenantSettingsRow;
}

function CreateInvoiceShiftItem({
  serviceTransaction,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafFormRef: FormRenderProps | null;

  const moduleName = TimeSheetSummaryModuleName.InvoiceTransaction;

  const [state, setState] = React.useState<IState>({
    initialValues: null,
    tenantSettings: null,
    variantServiceContractItem: null,
  });

  useEffect(() => {
    refresh();
  }, [serviceTransaction, props.relatedToUID]);

  const refresh = async () => {
    const initialValues = new InvoiceTransactionRow();

    initialValues.BillingStatus = RAFShiftActionStatus.Ready;
    if (isNotNullAndUndefined(serviceTransaction)) {
      initialValues.StartDate = serviceTransaction.StartDate;
      initialValues.EndDate = serviceTransaction.EndDate;
      initialValues.ActualStartDate = serviceTransaction.ActualStartDate;
      initialValues.ActualEndDate = serviceTransaction.ActualEndDate;
      initialValues.Assignee = serviceTransaction.Assignee;
      initialValues.AssigneeUID = serviceTransaction.AssigneeUID;
      initialValues.AssigneeType = serviceTransaction.AssigneeType;
      initialValues.RelatedTo = serviceTransaction.RelatedTo;
      initialValues.RelatedToUID = serviceTransaction.RelatedToUID;
      initialValues.RelatedToType = serviceTransaction.RelatedToType;
      initialValues.AssignTeam = serviceTransaction.AssignTeam;
      initialValues.AssignTeamUID = serviceTransaction.AssignTeamUID;

      initialValues.Status = serviceTransaction.Status;
      initialValues.Shift = serviceTransaction.Title;
      initialValues.Title = serviceTransaction.Title;
      initialValues.ShiftUID = serviceTransaction.UID;
      initialValues.Shifts = [
        { UID: serviceTransaction.UID, Value: serviceTransaction.Title },
      ];
    }

    if (isNotNullAndUndefined(props.relatedToUID)) {
      initialValues.RelatedToUID = props.relatedToUID;
    }

    if (isNotNullAndUndefined(props.relatedTo)) {
      initialValues.RelatedTo = props.relatedTo;
    }

    if (isNotNullAndUndefined(props.relatedToType)) {
      initialValues.RelatedToType = props.relatedToType;
    }

    if (isNotNullAndUndefined(props.startDate)) {
      initialValues.StartDate = props.startDate;
    }

    if (isNotNullAndUndefined(props.endDate)) {
      initialValues.EndDate = props.endDate;
    }

    if (isNotEmptyArray(props.shiftIds) && props.shiftIds.length > 1) {
      initialValues.Shifts = props.shiftIds;
      initialValues.Title = props.shiftIds[0].Value;
      initialValues.Status = RAFShiftActionStatus.Completed;
    }

    //initialValues.EnableTravel = false;
    // initialValues.Type = "Shift";
    // initialValues.RateType = "Hourly Rate";
    initialValues.State = RAFBPCurrentStatus.Published;
    initialValues.ShiftType = "Active";

    let tenantSettings = await getUserTenantSettings();
    setState({ initialValues: initialValues, tenantSettings });
  };

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    saveObject: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>,
    initialValues: InvoiceTransactionRow
  ) => {
    let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
    let shiftUpdate: InvoiceTransactionRow = R.clone(values);

    const duration = shiftUpdate.Duration;
    let serviceType = shiftUpdate.ServiceType;
    let rateType = shiftUpdate.RateType;

    if (isNotNullAndUndefined(values["ServiceItemUID"])) {
      //commented by hari 07-03-2025 as per the discussion with raj & raja
      // if (serviceType === "Travel") {
      //   shiftUpdate.TravelVariant = values["ServiceItem"];
      //   shiftUpdate.TravelVariantUID = values["ServiceItemUID"];
      // } else if (serviceType === "Service") {
      //   shiftUpdate.Variant = values["ServiceItem"];
      //   shiftUpdate.VariantUID = values["ServiceItemUID"];
      // }

      shiftUpdate.Variant = values["ServiceItem"];
      shiftUpdate.VariantUID = values["ServiceItemUID"];

      delete shiftUpdate["ServiceItem"];
      delete shiftUpdate["ServiceItemUID"];
    }

    let durationInMinutes = 0;

    if (serviceType === "Service") {
      if (duration > 0) {
        if (
          rateType === "Hourly Rate" ||
          rateType === "H" ||
          rateType === "E"
        ) {
          durationInMinutes = duration;
        } else if (rateType === "WK") {
          durationInMinutes = convertToMinutes(duration, "week");
        } else if (rateType === "D") {
          durationInMinutes = convertToMinutes(duration, "day");
        } else if (rateType === "MON") {
          durationInMinutes = convertToMinutes(duration, "month");
        } else if (rateType === "YR") {
          durationInMinutes = convertToMinutes(duration, "year");
        }
      }
    }

    shiftUpdate.Duration = durationInMinutes;

    const initialBillingStatus = initialValues.BillingStatus;
    const currentBillingStatus = shiftUpdate.BillingStatus;
    if (initialBillingStatus !== currentBillingStatus) {
      if (
        isNotNullAndUndefined(serviceTransaction) &&
        isNotNullAndUndefined(serviceTransaction.UID)
      ) {
        if (
          serviceTransaction.Status !== RAFShiftActionStatus.Unassigned &&
          serviceTransaction.Status !== RAFShiftActionStatus.Scheduled &&
          serviceTransaction.Status !== RAFShiftActionStatus.InProgress
        ) {
          if (shiftUpdate.BillingStatus === RAFShiftActionStatus.Ready) {
            if (shiftUpdate.Status === RAFShiftActionStatus.Approved) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Approved;
            } else if (shiftUpdate.Status === RAFShiftActionStatus.Cancelled) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Cancelled;
            }
            // else {
            //   hideProgress(progressDiv);
            //   showWarningToast(
            //     "Please approve the shift before sending invoice"
            //   );
            //   return;
            // }
          } else {
            shiftUpdate.BillingStatus = shiftUpdate.BillingStatus;
          }
        } else {
          hideProgress(progressDiv);
          showWarningToast(
            "Please complete this shift before updating billing status"
          );
          return;
        }
      }
    }

    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      shiftUpdate = EvaluateScore(values, queryAttributes);
    }

    // const duration = getTimeDifferenceInMinutes(
    //   shiftUpdate.StartDate,
    //   shiftUpdate.EndDate
    // );
    //shiftUpdate.Duration = duration;

    // console.log("shiftUpdate", shiftUpdate);
    // hideProgress(progressDiv);
    // return;

    saveObject(shiftUpdate)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");
          hideProgress(progressDiv);
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast(
            "Apologies, we're unable to update the record at the moment. Please try again later.!"
          );
        }
      })
      .catch((error) => error);
  };

  const isStartDateGreaterThanEndDate = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "End date should be greater than start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const isEndDateLessThan24hrs = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (getTimeDifferenceInMinutes(startDate, endDate) > 1440) {
            hasError = true;
            error = "End date should be within 24 hours from start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const onSelectServiceContractItem = async (label, serviceContractItemUID) => {
    const startDate = getFormValue(rafFormRef, "StartDate");
    const endDate = getFormValue(rafFormRef, "EndDate");
    const shiftType = getFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ShiftType")
    );
    if (IsNullOrWhiteSpace(startDate) && IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select Start Date and End Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(startDate)) {
      showWarningToast("Please select Start Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select End Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }

    if (
      getFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("EnableTravel")
      )
    ) {
    } else {
      updateVariantServiceContractItem(
        getFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("RelatedToUID")
        ),
        serviceContractItemUID,
        startDate,
        endDate,
        shiftType
      );
    }
  };

  const onBillingLineItemChanged = async (label, billingLineItem) => {
    if (isNotNullAndUndefined(billingLineItem)) {
    }
    let serviceContractItem: ServiceContractItemRow = await RetrieveRecord(
      billingLineItem,
      CareEsioEntity.CareServiceContractItem.EntityName
    );
    //console.log("serviceContractItem", serviceContractItem);

    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("Duration"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("TravelDistance"),
      null
    );
    setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Type"), null);
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("RateType"),
      null
    );
    setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Rate"), null);
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("EnableTravel"),
      false
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceCode"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceName"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceType"),
      null
    );

    if (isNotNullAndUndefined(serviceContractItem)) {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("RateType"),
        serviceContractItem.RateType
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Rate"),
        serviceContractItem.Rate
      );

      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceCode"),
        serviceContractItem.ServiceCode
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceName"),
        serviceContractItem.ServiceName
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceType"),
        serviceContractItem.ServiceType
      );

      if (serviceContractItem.ServiceType === "Travel") {
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("EnableTravel"),
          true
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Type"),
          "Travel"
        );
      } else {
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("EnableTravel"),
          false
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Type"),
          "Shift"
        );
      }
    } else {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Duration"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("TravelDistance"),
        null
      );
      setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Type"), null);
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("RateType"),
        null
      );
      setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Rate"), null);

      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("EnableTravel"),
        false
      );

      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceCode"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceName"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceType"),
        null
      );
    }
  };

  const updateVariantServiceContractItem = async (
    clientUID: string,
    serviceContractItemUID,
    startDate: Date,
    endDate: Date,
    shiftType: string
  ) => {
    if (
      isNotNullAndUndefined(clientUID) &&
      isNotNullAndUndefined(serviceContractItemUID) &&
      isNotNullAndUndefined(startDate) &&
      isNotNullAndUndefined(endDate)
    ) {
      // const [variantServiceContractItem, travelVariantLookUpItems] =
      //   await Promise.all([
      //     getServiceContractItemVariant(
      //       serviceContractItemUID,
      //       startDate,
      //       endDate,
      //       shiftType
      //     ),
      //     getAllTravelVariantLookUpItems(serviceContractItemUID, shiftType),
      //   ]);

      const variantServiceContractItem = await getServiceContractItemVariant(
        serviceContractItemUID,
        startDate,
        endDate,
        shiftType
      );

      if (isNotNullAndUndefined(variantServiceContractItem)) {
        showSuccessToast(
          "Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Variant"),
          variantServiceContractItem.Name
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("VariantUID"),
          variantServiceContractItem.UID
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("RateType"),
          variantServiceContractItem.RateType
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Rate"),
          variantServiceContractItem.Rate
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("ServiceCode"),
          variantServiceContractItem.ServiceCode
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("ServiceName"),
          variantServiceContractItem.ServiceName
        );
        // if (isNotEmptyArray(travelVariantLookUpItems)) {
        //   const travelVariantLookUpItem = travelVariantLookUpItems[0];
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("EnableTravel"),
        //     true
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //     travelVariantLookUpItem.Value
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //     travelVariantLookUpItem.UID
        //   );
        // } else {
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("EnableTravel"),
        //     null
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //     null
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //     null
        //   );
        // }
        setState({ ...state, variantServiceContractItem });
      } else {
        showWarningToast(
          "No Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Variant"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("VariantUID"),
          null
        );
        // setFormValue(
        //   rafFormRef,
        //   propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //   null
        // );
        // setFormValue(
        //   rafFormRef,
        //   propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //   null
        // );
        setState({ ...state, variantServiceContractItem: null });
      }
    } else {
      if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
        showWarningToast("Please select Start Date and End Date");
      }
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Variant"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("VariantUID"),
        null
      );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("TravelVariant"),
      //   null
      // );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
      //   null
      // );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("EnableTravel"),
      //   false
      // );
    }
  };

  const getServiceContractItemLookUp = () => {
    const { tenantSettings } = state;
    const isContractItemRequired = isNotNullAndUndefined(tenantSettings)
      ? tenantSettings.ContractItemRequired === "Yes"
        ? true
        : false
      : false;
    return (
      <Field name={propertyOf<InvoiceTransactionRow>("RelatedToUID")}>
        {({ input }) => {
          let customFilter: RAFCustomFilter = {};
          customFilter.Condition = "and";
          customFilter.Rules = [];
          if (isNotNullAndUndefined(input.value)) {
            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(input.value);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = "CareRecipientUID";
            customFilter.Rules.push(filter);
          }

          let parentFilter: RAFCustomFilter = {};
          parentFilter.Operator = RAFCustomOperator.IsNull;
          parentFilter.Field = "ParentUID";
          customFilter.Rules.push(parentFilter);

          return (
            <RAFLookUpMUI<InvoiceTransactionRow>
              field={propertyOf<InvoiceTransactionRow>("ServiceContractItem")}
              label="Service Item"
              placeholder="Select Service Item"
              url="ServiceContractItem/Lookup"
              moduleName={CareEsioEntity.CareServiceContractItem.EntityName}
              customFilter={customFilter}
              showLabel
              // onChanged={(label, value) =>
              //   onSelectServiceContractItem(label, value)
              // }
              closeToolTip={false}
              showFullList={false}
              //required={isContractItemRequired}//commented by hari 07-03-2025
              required
              formGroupClassName="mb-0"
            />
          );
        }}
      </Field>
    );
  };

  const getServiceContractItemVariationLookUp = () => {
    return (
      <Field name={propertyOf<InvoiceTransactionRow>("ServiceContractItemUID")}>
        {({ input }) => {
          const serviceContractItemUID = IsNotNullOrWhiteSpace(input.value)
            ? input.value
            : null;
          let additionalParams = [];
          if (isNotNullAndUndefined(input.value)) {
            additionalParams = [
              {
                key: "ParentUID",
                value: serviceContractItemUID,
              },
            ];
          }

          return (
            <>
              <div className="col-12">
                <Field name={propertyOf<InvoiceTransactionRow>("ShiftType")}>
                  {({ input }) => {
                    let customFilter: RAFCustomFilter = {};
                    customFilter.Condition = "and";
                    customFilter.Rules = [];
                    // if (isNotNullAndUndefined(input.value)) {
                    //   let filter: RAFCustomFilter = {};
                    //   let filterVal: string[] = [];
                    //   filterVal.push(input.value);
                    //   filter.Operator = RAFCustomOperator.Equal;
                    //   filter.Value = filterVal;
                    //   filter.Field = "ShiftType";
                    //   customFilter.Rules.push(filter);
                    // }
                    if (isNotNullAndUndefined(serviceContractItemUID)) {
                      let filter: RAFCustomFilter = {};
                      let filterVal: string[] = [];
                      filterVal.push(serviceContractItemUID);
                      filter.Operator = RAFCustomOperator.Equal;
                      filter.Value = filterVal;
                      filter.Field = "ParentUID";
                      customFilter.Rules.push(filter);
                    }
                    return (
                      <RAFLookUpMUI<InvoiceTransactionRow>
                        field={"ServiceItem"}
                        label="Billing line Item"
                        placeholder="Select Billing line Item"
                        url="ServiceContractItem/Lookup"
                        customFilter={customFilter}
                        moduleName={
                          CareEsioEntity.CareServiceContractItem.EntityName
                        }
                        //additionalParams={additionalParams}
                        showLabel
                        closeToolTip={false}
                        showFullList={true}
                        disabled={
                          IsNotNullOrWhiteSpace(serviceContractItemUID)
                            ? false
                            : true
                        }
                        onChanged={(label, value) => {
                          onBillingLineItemChanged(label, value);
                        }}
                        required={
                          IsNotNullOrWhiteSpace(serviceContractItemUID)
                            ? true
                            : false
                        }
                        formGroupClassName="mb-0"
                      />
                    );
                  }}
                </Field>
                {/*commented by hari 07-03-2025*/}
                {/* {IsNotNullOrWhiteSpace(serviceContractItemUID) && (
                  <div className="body_3_light mt-2">
                    <RAFDetailsField field="Variant" />
                  </div>
                )} */}
              </div>
              {/* <div className="col-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
              </div> */}
            </>
          );
        }}
      </Field>
    );
  };

  const onChangeEnableTravel = (value) => {
    if (value === true) {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("RateType"),
        "Kilometre"
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Type"),
        "Travel"
      );
    } else {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Type"),
        serviceTransaction.Type
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("TravelDistance"),
        null
      );
    }

    // setFormValue(
    //   rafFormRef,
    //   propertyOf<InvoiceTransactionRow>("TravelVariant"),
    //   null
    // );
    // setFormValue(
    //   rafFormRef,
    //   propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
    //   null
    // );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("Variant"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("VariantUID"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
      null
    );

    setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Rate"), null);

    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceCode"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceName"),
      null
    );
  };

  const onSelectTravel = async (label, value) => {
    getTravelItem(value);
  };

  const getTravelItem = async (id) => {
    const travelItem = await getTravelItemById(id);
    if (isNotNullAndUndefined(travelItem)) {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Rate"),
        travelItem.Rate
      );

      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceCode"),
        travelItem.ServiceCode
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceName"),
        travelItem.ServiceName
      );
    }
  };

  const getServiceContractItemLookup = () => {
    return (
      <>
        {/* <div className="col-12">
          <RAFCheckBox
            field={propertyOf<InvoiceTransactionRow>("EnableTravel")}
            label="Is Travel?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeEnableTravel}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          />
        </div>
        <Condition
          when={propertyOf<InvoiceTransactionRow>("EnableTravel")}
          is={true}
        >
          {getServiceContractItemLookUp()}
          <div className="col-12">
            <Field
              name={propertyOf<InvoiceTransactionRow>("ServiceContractItemUID")}
            >
              {({ input }) => {
                const serviceContractItemUID = IsNotNullOrWhiteSpace(
                  input.value
                )
                  ? input.value
                  : null;
                let additionalParams = [];
                if (isNotNullAndUndefined(input.value)) {
                  additionalParams = [
                    {
                      key: "ParentUID",
                      value: serviceContractItemUID,
                    },
                  ];
                }

                return (
                  <div>
                    <Field
                      name={propertyOf<InvoiceTransactionRow>("ShiftType")}
                    >
                      {({ input }) => {
                        let customFilter: RAFCustomFilter = {};
                        customFilter.Condition = "and";
                        customFilter.Rules = [];
                        if (isNotNullAndUndefined(input.value)) {
                          let filter: RAFCustomFilter = {};
                          let filterVal: string[] = [];
                          filterVal.push(input.value);
                          filter.Operator = RAFCustomOperator.Equal;
                          filter.Value = filterVal;
                          filter.Field = "ShiftType";
                          customFilter.Rules.push(filter);
                        }
                        return (
                          <RAFLookUpMUI<InvoiceTransactionRow>
                            field={propertyOf<InvoiceTransactionRow>(
                              "TravelVariant"
                            )}
                            label="Travel Variant"
                            placeholder="Select Travel Variant"
                            url="ServiceContractItem/TravelVariantLookup"
                            customFilter={customFilter}
                            moduleName={
                              CareEsioEntity.CareServiceContractItem.EntityName
                            }
                            additionalParams={additionalParams}
                            showLabel
                            closeToolTip={false}
                            showFullList={false}
                            // disabled={
                            //   IsNotNullOrWhiteSpace(serviceContractItemUID)
                            //     ? false
                            //     : true
                            // }
                            onChanged={(label, value) => {
                              onSelectTravel(label, value);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <div className="col-md-12">
                      <RAFNumber
                        field={propertyOf<InvoiceTransactionRow>(
                          "TravelDistance"
                        )}
                        label="Travel Distance"
                      />
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
        </Condition>
        <Condition when="EnableTravel" is={false}>
          <div className="col-12">
            {getServiceContractItemLookUp()}
            {getServiceContractItemVariationLookUp()}
          </div>
        </Condition> */}

        <div className="col-12">{getServiceContractItemLookUp()}</div>
        <div className="col-12">{getServiceContractItemVariationLookUp()}</div>
      </>
    );
  };

  const getInputFormContent = () => {
    return (
      <div className="row gx-0 gy-3">
        {/* <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("StartDate")}
            label="Start Date"
            required
            interval={15}
            roundOff
          />
        </div>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("EndDate")}
            label="End Date"
            required
            interval={15}
            roundOff
            validators={[isStartDateGreaterThanEndDate, isEndDateLessThan24hrs]}
          />
        </div> */}
        {/* <div className="col-md-12">
          <RAFNumber
            field={propertyOf<InvoiceTransactionRow>("InvoiceAmount")}
            label="Invoice Amount"
            disabled
            decimalsPoints={2}
          />
        </div> */}
        <ConditionNotNullOrWhiteSpace
          when={propertyOf<InvoiceTransactionRow>("RelatedToUID")}
        >
          <>{getServiceContractItemLookup()}</>
        </ConditionNotNullOrWhiteSpace>
        <ConditionNotNullOrWhiteSpace when={"ServiceItemUID"}>
          <div className="col-12">
            <div className="row g-3 align-items-end">
              <Condition when="ServiceType" is={"Travel"}>
                <div className="col">
                  <RAFNumber
                    field={propertyOf<InvoiceTransactionRow>("TravelDistance")}
                    label="Unit"
                    disabled={false}
                    decimalsPoints={0}
                    formGroupClassName="mb-0"
                  />
                </div>
              </Condition>
              <Condition when="ServiceType" is={"Service"}>
                <>
                  <div className="col">
                    <ConditionIncludes
                      when="RateType"
                      is={["Hourly Rate", "H", "E"]}
                    >
                      <RAFDurationPicker
                        field={propertyOf<InvoiceTransactionRow>("Duration")}
                        label={"Unit"}
                        showLabel
                        formGroupClassName="mb-0"
                      />
                    </ConditionIncludes>
                    <ConditionNotIncludes
                      when="RateType"
                      isNot={["Hourly Rate", "H", "E"]}
                    >
                      <RAFNumber
                        field={propertyOf<InvoiceTransactionRow>("Duration")}
                        label={"Unit"}
                        showLabel
                        formGroupClassName="mb-0"
                      />
                    </ConditionNotIncludes>
                  </div>
                </>
              </Condition>
              <div className="col-auto" style={{ maxWidth: "150px" }}>
                <RAFTextBox
                  field={propertyOf<InvoiceTransactionRow>("RateType")}
                  label="Unit"
                  showLabel={false}
                  disabled
                  formGroupClassName="mb-0"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <RAFNumber
              field={propertyOf<InvoiceTransactionRow>("Rate")}
              label="Rate"
              disabled
              decimalsPoints={2}
              formGroupClassName="mb-0"
            />
          </div>
        </ConditionNotNullOrWhiteSpace>
        <div className="col-md-12">
          <RAFTextArea
            field={propertyOf<InvoiceTransactionRow>("Description")}
            label="Description"
            formGroupClassName="mb-0"
          />
        </div>
      </div>
    );
  };

  if (props.isActive) {
    return (
      <div
        className="h-100"
        key={moduleName}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity }) => {
                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({ saveObject }) => {
                              return (
                                <RAFForm
                                  initialValues={state.initialValues}
                                  formRef={(g) => (rafFormRef = g)}
                                  layout={RAFLayout.TwoColumnLayout}
                                  onSubmit={(values) =>
                                    onSubmitObject(
                                      values,
                                      entity,
                                      queryAttributes,
                                      saveObject,
                                      state.initialValues
                                    )
                                  }
                                  className="h-100"
                                >
                                  <div className="e-dlg-content-outer">
                                    <div className="e-dlg-body-content">
                                      {getInputFormContent()}
                                    </div>
                                    <div className="e-dlg-footerContent">
                                      <div className="w-100">
                                        <div className="row gx-2">
                                          <div className="col-auto ms-auto">
                                            <RAFPermissionRender
                                              permissionName={
                                                InvoiceTransactionPermissionConstants.InvoiceTransactionManageBilling
                                              }
                                            >
                                              <RAFButtonComponent
                                                isPrimary
                                                action={RAFButtonConstant.Save}
                                                onClick={() =>
                                                  rafFormRef &&
                                                  rafFormRef.form.submit()
                                                }
                                                idString="EditContent"
                                              />
                                            </RAFPermissionRender>
                                          </div>
                                          <div className="col-auto">
                                            <RAFButtonComponent
                                              action={RAFButtonConstant.Cancel}
                                              onClick={props.onClose}
                                              idString="EditContent"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </RAFForm>
                              );
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CreateInvoiceShiftItem);
