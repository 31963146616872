import moment from "moment";
import * as R from "ramda";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  currencyFormatting,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import {
  RAFShiftActionStatus,
  RAFStatusNameWithColor,
} from "../../../../constants/Common/Constants";
import { InvoiceTransactionRow } from "./CareBilling/InvoiceTransactionRow";
import { PayrollTransactionRow } from "./TimeSheet/PayrollTransactionRow";

export const shiftSeriveStatusTemplate = (data) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];

  if (isNotNullAndUndefined(fieldValue) && fieldValue) {
    let status = fieldValue;
    let statusClass = "";
    let statusbgColor;
    if (fieldValue === RAFShiftActionStatus.Completed) {
      status = RAFShiftActionStatus.Pending;
      statusClass = "e-badge-info";
      statusbgColor = "#0078d6";
    } else if (fieldValue === RAFShiftActionStatus.Approved) {
      statusClass = "e-badge-success";
      statusbgColor = "#107c10";
    } else if (fieldValue === RAFShiftActionStatus.Rejected) {
      statusClass = "e-badge-danger";
      statusbgColor = "#a80000";
    }
    let colorCodeName = RAFStatusNameWithColor[fieldValue];

    return (
      // <div className="custom_key_value_outter">
      //   <div
      //     className="custom_key_value e-badge"
      //     style={{ backgroundColor: statusbgColor, color: "#fff" }}
      //   >
      //     <span> {status} </span>
      //   </div>
      <span
        className="e-badge e-badge-xlg e-badge-custom"
        style={{
          backgroundColor: isNotNullAndUndefined(colorCodeName)
            ? colorCodeName.Color
            : "#333",
          color: "#fff",
        }}
      >
        {isNotNullAndUndefined(colorCodeName)
          ? colorCodeName.DisplayName
          : isNotNullAndUndefined(status)
            ? status
            : "BillingStatus"}
      </span>
      // </div>
    );
  }
  return <div>{fieldValue}</div>;
};

export const getDurationDisplayText1 = (totalMinutes: number) => {
  if (IsNotNullOrWhiteSpace(totalMinutes)) {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;

    // let timeString = `${hours.toString().padStart(2, "0")}:${minutes
    //   .toString()
    //   .padStart(2, "0")}`;

    let timeString = `${hours} hour(s) ${minutes} minute(s)`;

    if (hours === 0) {
      timeString = `${minutes} minute(s)`;
    } else if (minutes === 0) {
      timeString = `${hours} hour(s)`;
    }
    return timeString;
  }
  return null;
};

export const getDurationDisplayText = (totalMinutes: number) => {
  let durationInMinutes = R.clone(totalMinutes);
  const years = Math.floor(durationInMinutes / (60 * 24 * 365));
  durationInMinutes %= 60 * 24 * 365;
  const months = Math.floor(durationInMinutes / (60 * 24 * 30));
  durationInMinutes %= 60 * 24 * 30;
  const days = Math.floor(durationInMinutes / (60 * 24));
  durationInMinutes %= 60 * 24;
  const hours = Math.floor(durationInMinutes / 60);
  durationInMinutes %= 60;
  const minutes = durationInMinutes;

  let durationText = "";
  if (years > 0) durationText += `${years} year(s) `;
  if (months > 0) durationText += `${months} month(s) `;
  if (days > 0) durationText += `${days} day(s) `;
  if (hours > 0) durationText += `${hours} hour(s) `;
  if (minutes > 0) durationText += `${minutes} minute(s) `;

  return durationText.trim();
};

export const shiftPayRollDurationTemplate = (data: PayrollTransactionRow) => {
  const status = data["Status"];
  const actualDuration = data["ActualDuration"];
  const totalMinutes =
    status === RAFShiftActionStatus.Scheduled
      ? data["Duration"]
      : actualDuration;

  const timeString = getDurationDisplayText(totalMinutes);

  if (totalMinutes > 0) {
    return <div>{timeString}</div>;
  }
  return <div>{"\u00A0"}</div>;
};

export const payRollDateTitleTemplate = (data: PayrollTransactionRow) => {
  const status = data["Status"];
  const startDate =
    status === RAFShiftActionStatus.Scheduled
      ? data["StartDate"]
      : data["ActualStartDate"];
  const endDate =
    status === RAFShiftActionStatus.Scheduled
      ? data["EndDate"]
      : data["ActualEndDate"];
  return getStartDateStartTimeEndTimeDisplayTemplate(startDate, endDate);
};

export const shiftActualDurationTemplate = (data) => {
  const totalMinutes = data["ActualDuration"];

  const timeString = getDurationDisplayText(totalMinutes);

  if (totalMinutes > 0) {
    return <div>{timeString}</div>;
  }
  return <div>{"\u00A0"}</div>;
};

export const shiftDurationTemplate = (data) => {
  const totalMinutes = data["Duration"];
  const timeString = getDurationDisplayText(totalMinutes);

  if (totalMinutes > 0) {
    return <div>{timeString}</div>;
  }
  return <div>{"\u00A0"}</div>;
};

export const currencyTemplate = (fieldName, data) => {
  const rateValue = data[fieldName];
  return currencyFormatting(rateValue, "USD");
};

export const durationTemplate = (fieldName, data, showHours?: boolean) => {
  const totalMinutes = data[fieldName];
  // Calculate hours and minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (totalMinutes > 0) {
    // Format the duration as 'hh:mm hrs'
    let durationString
    if (showHours === true) {
      durationString = `${hours}h`;
    }
    else {
      durationString = `${hours}:${minutes.toString().padStart(2, "0")} hrs`;
    }

    return durationString;
  } else {
    const durationString = "0:00 hr";
    return durationString;
  }
};

export const getStartDateStartTimeEndTimeDisplayValue = (
  startDate: Date,
  endDate: Date
) => {
  if (isNotNullAndUndefined(startDate)) {
    const startedDate = convertUTCDateToLocalTimezone(startDate);
    const endedDate = convertUTCDateToLocalTimezone(endDate);
    const startTime = moment(startedDate).format("hh:mm A");

    if (isNotNullAndUndefined(endedDate)) {
      const endTime = moment(endedDate).format("hh:mm A");
      return `${moment(startedDate).format(
        "DD/MM/YYYY"
      )}  ${startTime} - ${endTime}`;
    } else {
      return `${moment(startedDate).format("DD/MM/YYYY hh:mm A")}`;
    }
  }
  return "";
};

export const getStartDateStartTimeEndTimeDisplayTemplate = (
  startDate: Date,
  endDate: Date
) => {
  if (isNotNullAndUndefined(startDate)) {
    return (
      <div className="custom_key_value_outter">
        {" "}
        {getStartDateStartTimeEndTimeDisplayValue(startDate, endDate)}
      </div>
    );
  }
  return <div></div>;
};

export const shiftDateTitleTemplate = (data: InvoiceTransactionRow) => {
  const startDate = data["StartDate"];
  const endDate = data["EndDate"];
  return getStartDateStartTimeEndTimeDisplayTemplate(startDate, endDate);
};

export const shifActualDateTitleTemplate = (data) => {
  const actualStartDate = data["ActualStartDate"];
  const actualEndDate = data["ActualEndDate"];
  return getStartDateStartTimeEndTimeDisplayTemplate(
    actualStartDate,
    actualEndDate
  );
};
